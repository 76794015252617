import axios from "axios";
export const state = {
    isLoading: false,
    usersList: [],
    usersData: {},
    newUser: null,
    userErrorMsg: null,
    userSuccessMsg: null,

    isSearchingUsers: false, 

    searchUsersData: {},

    usersPage: null,
}

export const mutations = {
    SET_LOADING(state, isLoading) {
        state.isLoading = isLoading;
    },
    SET_USERS_LIST(state, usersList) {
        state.usersList = usersList
    },
    SET_USERS_DATA(state, usersData){
        state.usersData = usersData
    },
    SET_IS_SEARCHING(state, isSearchingUsers) {
        state.isSearchingUsers = isSearchingUsers;
    },
    ADD_USER(state, newUser){
        state.usersList.push(newUser)
    },
    DELETE_USER(state, userId){
        state.usersList = state.usersList.filter(user => user.id !== userId)
    },
    SET_ERROR_MSG(state, msg){
        state.userErrorMsg = msg
    },
    SET_SUCCESS_MSG(state, msg){
        state.userSuccessMsg = msg
    },
    UPDATE_USER(state, updatedUser){
        state.usersList = state.usersList.map(
            user => 
            user.id === updatedUser.id ? updatedUser : user)
    },

    SET_SEARCH_DATA(state, searchUsersData) {
        state.searchUsersData = searchUsersData;
    },

    SET_USERS_PAGE(state, usersPage){
        state.usersPage = usersPage
    }
}

export const getters = {
    getUsersList: state => state.usersList,
    usersData: state => state.usersData,
    isLoading: state => state.isLoading,
    newUser: state => state.newUser,
    userErrorMsg: state => state.userErrorMsg,
    userSuccessMsg: state => state.userSuccessMsg,
    isSearchingUsers: state => state.isSearchingUsers,
    searchUsersData: state => state.searchUsersData,
    usersPage: state => state.usersPage
}

export const actions = {
    async fetchUsersList({commit}, page = 1){
        commit('SET_LOADING', true);

        await axios.get(`/api/v1/users?page=${page}`)
        .then(response => {
            const { data } = response;
            commit('SET_USERS_LIST', data)
            commit('SET_ERROR_MSG', null)
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_LOADING', false);
        });
    },

    async getUsersData({commit}, page = 1){
        commit('SET_LOADING', true);
         
        await axios.get(`/api/v1/users?page=${page}`)
        .then(response => {
            const { data } = response;
            commit('SET_USERS_DATA', data)
            commit('SET_ERROR_MSG', null)
            commit('SET_USERS_PAGE', response.data.meta.current_page)
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_LOADING', false);
        });
    },

    async getSearchUsersData({ commit }, { page, searchDataObject }) {
        commit('SET_LOADING', true);
        commit('SET_IS_SEARCHING', true);

        commit('SET_SEARCH_DATA', searchDataObject);
      
        await axios.post(`api/v1/search-users?page=${page}`, searchDataObject)
          .then((response) => {
            const { data } = response;
            commit('SET_USERS_DATA', data);
            commit('SET_ERROR_MSG', null); 
            commit('SET_USERS_PAGE', response.data.meta.current_page);
          })
          .catch((error) => {
            commit('SET_ERROR_MSG', error.response.data.message);
          })
          .finally(() => {
            commit('SET_LOADING', false);
          });
    },

    async addUser({commit}, newUser){
        await axios.post(`/api/v1/users`, newUser)
        .then(response => {
            const { data } = response;
            commit('ADD_USER', data)
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Thank you for your submission')
        })
        .catch(error => { 
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },


    async deleteUser({commit}, userId){
        await axios.delete(`/api/v1/users/${userId}`)
        .then(() => { 
            commit('DELETE_USER', userId)
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'User deleted successfully');
        })
        .catch(error => { 
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },

    async deleteMultipleUsers({commit}, userIds){
        await userIds.forEach(userId => {
            axios.delete(`/api/v1/users/${userId}`) 
            .then(() => { 
                commit('DELETE_USER', userId)
                commit('SET_ERROR_MSG', null)
                commit('SET_SUCCESS_MSG', 'Users deleted successfully')
            })
            .catch(error => { 
                commit('SET_ERROR_MSG', error.response.data.message)
            })
        })     
    },


    async updateUser({commit}, updatedUser){
        await axios.patch(`/api/v1/users/${updatedUser.id}`, updatedUser)
        .then(() =>{ 
            commit('UPDATE_USER', updatedUser)
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'User updated successfully')
        })
        .catch(error => { 
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },

    async updateProfile({commit}, updatedUser){
        await axios.patch(`/api/v1/profile`, updatedUser)
        .then(() =>{ 
            commit('UPDATE_USER', updatedUser)
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'User updated successfully')
        })
        .catch(error => { 
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    }
}