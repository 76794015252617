import axios from "axios";

export const state = {
    areVacanciesLoading: false,
    vacanciesList: [],
    vacanciesData: {},
    newVacancy: null,
    vacancyErrorMsg: null,
    vacancySuccessMsg: null,

    isSearchingVacancies: false, 

    searchVacanciesData: {},

    vacanciesPage: null,
}

export const mutations = {
    SET_LOADING(state, areVacanciesLoading){
        state.areVacanciesLoading = areVacanciesLoading
    },
    SET_VACANCIES_LIST(state, vacanciesList){
        state.vacanciesList = vacanciesList
    },
    SET_VACANCIES_DATA(state, vacanciesData){
        state.vacanciesData = vacanciesData
    },
    SET_IS_SEARCHING(state, isSearchingVacancies) {
        state.isSearchingVacancies = isSearchingVacancies;
    },
    ADD_VACANCY(state, newVacancy){
        state.newVacancy = newVacancy
    },
    DELETE_VACANCY(state, vacancyId){
        state.vacanciesList = state.vacanciesList.filter(vacancy => vacancy.id !== vacancyId)
    },
    UPDATE_VACANCY(state, updatedVacancy){
        state.vacanciesList = state.vacanciesList.map(vacancy => 
            vacancy.id === updatedVacancy.id ? updatedVacancy : vacancy
        )
    },
    SET_ERROR_MSG(state, vacancyErrorMsg){
        state.vacancyErrorMsg = vacancyErrorMsg
    },
    SET_SUCCESS_MSG(state, vacancySuccessMsg){
        state.vacancySuccessMsg = vacancySuccessMsg
    },

    SET_SEARCH_DATA(state, searchVacanciesData) {
        state.searchVacanciesData = searchVacanciesData;
    },

    SET_VACANCIES_PAGE(state, vacanciesPage){
        state.vacanciesPage = vacanciesPage
    }
}

export const getters = {
    vacanciesList: state => state.vacanciesList,
    vacanciesData: state => state.vacanciesData,
    areVacanciesLoading: state => state.areVacanciesLoading,
    newVacancy: state => state.newVacancy,
    vacancyErrorMsg: state => state.vacancyErrorMsg,
    vacancySuccessMsg: state => state.vacancySuccessMsg,
    isSearchingVacancies: state => state.isSearchingVacancies,
    searchVacanciesData: state => state.searchVacanciesData,
    vacanciesPage: state => state.vacanciesPage
}

export const actions = {
    async fetchVacanciesList({commit}, page = 1){
        commit('SET_LOADING', true)

        await axios.get(`/api/v1/vacancies?page=${page}`)
        .then(response => {
            const {data} = response
            commit('SET_VACANCIES_LIST', data)
            commit('SET_ERROR_MSG', null)
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_LOADING', false);
        });
    },

    async getVacanciesData({commit}, page = 1){
        commit('SET_LOADING', true)

        await axios.get(`/api/v1/vacancies?page=${page}`)
        .then(response => {
            const {data} = response
            commit('SET_VACANCIES_DATA', data)
            commit('SET_ERROR_MSG', null)
            commit('SET_VACANCIES_PAGE', response.data.meta.current_page)
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_LOADING', false);
        });
    }, 

    async getSearchVacanciesData({ commit }, { page, searchDataObject }) {
        commit('SET_LOADING', true);
        commit('SET_IS_SEARCHING', true);

        commit('SET_SEARCH_DATA', searchDataObject);
      
        await axios.post(`api/v1/search-vacancies?page=${page}`, searchDataObject)
          .then((response) => {
            const { data } = response;
            commit('SET_VACANCIES_DATA', data);
            commit('SET_ERROR_MSG', null); 
            commit('SET_VACANCIES_PAGE', response.data.meta.current_page)
          })
          .catch((error) => {
            commit('SET_ERROR_MSG', error.response.data.message);
          })
          .finally(() => {
            commit('SET_LOADING', false);
          });
    },


    async addVacancy({commit}, newVacancy){
        try {
            const response = await axios.post(`/api/v1/vacancies`, newVacancy);
            const { data } = response;
    
            commit('ADD_VACANCY', data);
            commit('SET_ERROR_MSG', null);
            commit('SET_SUCCESS_MSG', 'Thank you for your submission');
    
            const theId = data.data.id;
            return theId;
        } catch (error) {
            commit('SET_ERROR_MSG', error.response.data.message);
            throw error;
        }
    },

    async deleteVacancy({commit}, vacancyId){
        await axios.delete(`/api/v1/vacancies/${vacancyId}`)
        .then(() => {
            commit('DELETE_VACANCY', vacancyId);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Vacancy deleted successfully')
        })
        .catch(error =>{
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },

    async updateVacancy({commit}, updatedVacancy){
        await axios.patch(`/api/v1/vacancies/${updatedVacancy.id}`, updatedVacancy)
        .then(() => {
            commit('UPDATE_VACANCY', updatedVacancy);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Vacancy updated successfully')
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
}