<template>
<router-view></router-view>
</template>

<script>
import "@vueform/multiselect/themes/default.css";
export default {
    name: 'App',
}
</script>

<style>
.overflow-text{
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
}


.phone-name{
    border: 2px solid;
    height: 2.4rem;
    width: 2.4rem;
    border-radius: 50% !important;
}

[data-layout-mode="light"] .phone-name{
    border-color: #ef5613 !important;
}

.td-custom-height{
    height: 1em;
}

.form-page-header {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
}

.form-page-header a {
    cursor: pointer;
    color: var(--vz-modal-color);
}

.form-page-header span {
    color: var(--vz-modal-color) !important;
    margin: 0 5px;
}

.data-row {
    cursor: pointer;
    align-items: center;
}

.spinner-tbody {
    position: relative;
}

.spinner-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading-data-table {
    min-height: 70vh;
}

.edit-btn {
    display: block;
    width: 100%;
    line-height: var(--vz-body-line-height);
    padding: var(--vz-dropdown-item-padding-y) var(--vz-dropdown-item-padding-x);
    color: var(--vz-dropdown-link-color);
    color: var(--vz-dropdown-color);
}

.edit-btn:hover,
.edit-btn:focus {
    color: var(--vz-dropdown-link-hover-color);
    background-color: var(--vz-dropdown-link-hover-bg);
}

.btn-icon {
    height: calc(0.8rem + 0.8em + 2px) !important;
    width: calc(0.8rem + 0.8em + 2px) !important;
}


.trasparent{
  border: 1px solid transparent;

}

.bottom-border{
  /* border-bottom-color: rgb(135, 138, 153) */
  box-shadow: 0 1px  rgba(56, 65, 74, 0.15);
}

.right-border{
  border-right-color: rgba(56, 65, 74, 0.15)
}

.avatar-xs{
  height: 1.6rem !important;
  width: 1.6rem !important;
}

.notes-list:last-child{
  border-bottom-color: transparent;
  box-shadow: none !important;
}

.editNote, .addFile, .delNote{
  transition: 0.3s ease;
}

.editNote:hover, .addFile:hover{
  color: #a7abb8;
}

.delNote:hover{
  color: #ff9f8c !important;
}

.selected-row {
  background-color: var(--vz-table-hover-bg);
}



.swal2-styled.swal2-confirm{
  background-color:#ef5613 !important;
}

.swal2-confirm.swal2-styled.swal2-default-outline{
  background-color: rgb(52, 195, 143) !important; 
}


.skill-level::-webkit-inner-spin-button{
    display: block !important;
    opacity: 1 !important;
}

.skill-level{
    background: var(--vz-input-bg) !important;
    color: var(--vz-modal-color) !important;
    padding: 0.3em !important;
    border: 1px solid var(--vz-input-border) !important;
}

.skill-level:focus, .skill-level:focus-within, .skill-level:focus-visible{
    border: 1px solid var(--vz-input-focus-border) !important;
}

.skill-level::-webkit-inner-spin-button, .skill-level::-webkit-outer-spin-button {
    -webkit-appearance: auto !important;
    height: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--vz-input-border);
  -webkit-text-fill-color: var(--vz-body-color);
  -webkit-box-shadow: 0 0 0px 1000px var(--vz-input-bg) inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
