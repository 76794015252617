export default (permission) => {
    
    if(localStorage.getItem("user") === null || localStorage.getItem("user") === 'null'){
        window.location.href='/login'
        return null
    }
    const rolePermissions = JSON.parse(localStorage.getItem("user")).role.permissions

    return permission === "default"
        ? true
        : rolePermissions.some((p) => p.name === permission)
}