import axios from "axios";

export const state = {
    areCompaniesLoading: false,
    areCompanyVacanciesLoading: false,
    companiesList: [],
    companiesData: {},
    companiesVacanciesData: {},
    newCompany: null,
    companyErrorMsg: null,
    companySuccessMsg: null,
    isSearchingCompanies: false, 

    searchCompaniesData: {},

    companiesPage: null,
}

export const mutations = {
    SET_LOADING(state, areCompaniesLoading){
        state.areCompaniesLoading = areCompaniesLoading
    },
    SET_VACANCIES_LOADING(state, areCompaniesLoading){
        state.areCompaniesLoading = areCompaniesLoading
    },
    SET_COMPANIES_LIST(state, companiesList){
        state.companiesList = companiesList
    },
    SET_COMPANIES_DATA(state, companiesData){
        state.companiesData = companiesData
    },
    SET_IS_SEARCHING(state, isSearchingCompanies) {
        state.isSearchingCompanies = isSearchingCompanies;
    },
    ADD_COMPANY(state, newCompany){
        state.newCompany = newCompany
    },
    DELETE_COMPANY(state, companyId){
        state.companiesList = state.companiesList.filter(company => company.id !== companyId)
    },
    UPDATE_COMPANY(state, updatedCompany){
        state.companiesList = state.companiesList.map(company => 
            company.id === updatedCompany.id ? updatedCompany : company
        )
    },
    SET_ERROR_MSG(state, companyErrorMsg){
        state.companyErrorMsg = companyErrorMsg
    },
    SET_SUCCESS_MSG(state, companySuccessMsg){
        state.companySuccessMsg = companySuccessMsg
    },
    SET_COMPANY_VACANCIES(state, vacanciesData){
        state.companiesVacanciesData = vacanciesData
    },
    SET_SEARCH_DATA(state, searchCompaniesData) {
        state.searchCompaniesData = searchCompaniesData;
    },

    SET_COMPANIES_PAGE(state, companiesPage){
        state.companiesPage = companiesPage
    }
}

export const getters = {
    companiesList: state => state.companiesList,
    companiesData: state => state.companiesData,
    areCompaniesLoading: state => state.areCompaniesLoading,
    companiesVacanciesData: state => state.companiesVacanciesData,
    areCompanyVacanciesLoading: state => state.areCompanyVacanciesLoading,
    newCompany: state => state.newCompany,
    companyErrorMsg: state => state.companyErrorMsg,
    companySuccessMsg: state => state.companySuccessMsg,
    isSearchingCompanies: state => state.isSearchingCompanies,
    searchCompaniesData: state => state.searchCompaniesData,
    companiesPage: state => state.companiesPage
}

export const actions = {
    async fetchCompaniesList({commit}, page = 1){
        commit('SET_LOADING', true)

        await axios.get(`/api/v1/companies?page=${page}`)
        .then(response => {
            const {data} = response
            commit('SET_COMPANIES_LIST', data)
            commit('SET_ERROR_MSG', null)
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_LOADING', false);
        });
    },

    async getCompaniesData({commit}, page = 1){
        commit('SET_LOADING', true)

        await axios.get(`/api/v1/companies?page=${page}`)
        .then(response => {
            const {data} = response
            commit('SET_COMPANIES_DATA', data)
            commit('SET_ERROR_MSG', null)
            commit('SET_COMPANIES_PAGE', response.data.meta.current_page)
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_LOADING', false);
        });
    },

 
    async getSearchCompaniesData({ commit }, { page, searchDataObject }) {
        commit('SET_LOADING', true);
        commit('SET_IS_SEARCHING', true);

        commit('SET_SEARCH_DATA', searchDataObject);
      
        await axios.post(`api/v1/search-companies?page=${page}`, searchDataObject)
          .then((response) => {
            const { data } = response;
            commit('SET_COMPANIES_DATA', data);
            commit('SET_ERROR_MSG', null); 
            commit('SET_COMPANIES_PAGE', response.data.meta.current_page)
          })
          .catch((error) => {
            commit('SET_ERROR_MSG', error.response.data.message);
          })
          .finally(() => {
            commit('SET_LOADING', false);
          });
    },


    async getCompanyVacanciesData({commit}, companyId){
        commit('SET_VACANCIES_LOADING', true)

        await axios.get(`/api/v1/companies/${companyId}/vacancies`)
        .then(response => {
            const {data} = response
            commit('SET_COMPANY_VACANCIES', data)
            commit('SET_ERROR_MSG', null)
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_VACANCIES_LOADING', false);
        });
    },


    async addCompany({commit}, newCompany){
        try {
            const response = await axios.post(`/api/v1/companies`, newCompany);
            const { data } = response;
    
            commit('ADD_COMPANY', data);
            commit('SET_ERROR_MSG', null);
            commit('SET_SUCCESS_MSG', 'Thank you for your submission');
    
            const theId = data.data.id;
            return theId;
        } catch (error) {
            commit('SET_ERROR_MSG', error.response.data.message);
            throw error;
        }
    },

    async deleteCompany({commit}, companyId){
        await axios.delete(`/api/v1/companies/${companyId}`)
        .then(() => {
            commit('DELETE_COMPANY', companyId);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Company deleted successfully')
        })
        .catch(error =>{
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },

    async updateCompany({commit}, updatedCompany){
        await axios.patch(`/api/v1/companies/${updatedCompany.id}`, updatedCompany)
        .then(() => {
            commit('UPDATE_COMPANY', updatedCompany);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Company updated successfully')
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
}