import axios from "axios";

export const state = {
    areCandidatesLoading: false,
    candidatesList: [],
    candidatesData: {},
    newCandidate: null,
    candidateErrorMsg: null,
    candidateSuccessMsg: null,
    isSearchingCandidates: false, 
    searchData: {},
    candidatesPage: null,
}

export const mutations = {
    SET_LOADING(state, areCandidatesLoading){
        state.areCandidatesLoading = areCandidatesLoading
    },
    SET_CANDIDATES_LIST(state, candidatesList){
        state.candidatesList = candidatesList
    },
    SET_CANDIDATES_DATA(state, candidatesData){
        state.candidatesData = candidatesData
    },  
    SET_IS_SEARCHING(state, isSearchingCandidates) {
        state.isSearchingCandidates = isSearchingCandidates;
    },
    ADD_CANDIDATE(state, newCandidate){
        state.newCandidate = newCandidate
    },
    DELETE_CANDIDATE(state, candidateId){
        state.candidatesList = state.candidatesList.filter(candidate => candidate.id !== candidateId)
    },
    UPDATE_CANDIDATE(state, updatedCandidate){
        state.candidatesList = state.candidatesList.map(candidate => 
            candidate.id === updatedCandidate.id ? updatedCandidate : candidate
        )
    },
    SET_ERROR_MSG(state, candidateErrorMsg){
        state.candidateErrorMsg = candidateErrorMsg
    },
    SET_SUCCESS_MSG(state, candidateSuccessMsg){
        state.candidateSuccessMsg = candidateSuccessMsg
    },

    SET_SEARCH_DATA(state, searchData) {
        state.searchData = searchData;
    },

    SET_CANDIDATES_PAGE(state, candidatesPage){
        state.candidatesPage = candidatesPage
    }
}

export const getters = {
    candidatesList: state => state.candidatesList,
    candidatesData: state => state.candidatesData,
    areCandidatesLoading: state => state.areCandidatesLoading,
    newCandidate: state => state.newCandidate,
    candidateErrorMsg: state => state.candidateErrorMsg,
    candidateSuccessMsg: state => state.candidateSuccessMsg,
    isSearchingCandidates: state => state.isSearchingCandidates,
    searchData: state => state.searchData,
    candidatesPage: state => state.candidatesPage
}

export const actions = {
    async fetchCandidatesList({commit}, page = 1){
        commit('SET_LOADING', true)

        await axios.get(`/api/v1/candidates?page=${page}`)
        .then(response => {
            const {data} = response
            commit('SET_CANDIDATES_LIST', data)
            commit('SET_ERROR_MSG', null)
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_LOADING', false);
        });
    },

    async getCandidatesData({commit}, page = 1){
        commit('SET_LOADING', true)
 

        await axios.get(`/api/v1/candidates?page=${page}`)
        .then(response => {
            const {data} = response
            commit('SET_CANDIDATES_DATA', data)
            commit('SET_ERROR_MSG', null)
            commit('SET_CANDIDATES_PAGE', response.data.meta.current_page)
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_LOADING', false);
        });
    },

    async getSearchCandidatesData({ commit }, { page, searchDataObject }) {
        commit('SET_LOADING', true);
        commit('SET_IS_SEARCHING', true);

        commit('SET_SEARCH_DATA', searchDataObject);
        
        await axios.post(`api/v1/search-candidates?page=${page}`, searchDataObject)
            .then((response) => {
              const { data } = response;
              commit('SET_CANDIDATES_DATA', data);
              commit('SET_ERROR_MSG', null); 
              commit('SET_CANDIDATES_PAGE', response.data.meta.current_page);
            })
            .catch((error) => {
              commit('SET_ERROR_MSG', error.response.data.message);
            })
            .finally(() => {
              commit('SET_LOADING', false);
            });
    },

    async addCandidate({commit}, newCandidate){
        try {
            const response = await axios.post(`/api/v1/candidates`, newCandidate);
            const { data } = response;
    
            commit('ADD_CANDIDATE', data);
            commit('SET_ERROR_MSG', null);
            commit('SET_SUCCESS_MSG', 'Thank you for your submission');
    
            const theId = data.data.id;
            return theId;
        } catch (error) {
            commit('SET_ERROR_MSG', error.response.data.message);
            throw error;
        }
    },

    async deleteCandidate({commit}, candidateId){
        await axios.delete(`/api/v1/candidates/${candidateId}`)
        .then(() => {
            commit('DELETE_CANDIDATE', candidateId);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Candidate deleted successfully')
        })
        .catch(error =>{
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },

    async updateCandidate({commit}, updatedCandidate){
        await axios.patch(`/api/v1/candidates/${updatedCandidate.id}`, updatedCandidate)
        .then(() => {
            commit('UPDATE_CANDIDATE', updatedCandidate);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Candidate updated successfully')
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
}