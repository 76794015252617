import axios from "axios";
export const state = {
    areClientsLoading: false,
    clientsList: [],
    clientsData: {},
    newClient: null,
    clientErrorMsg: null,
    clientSuccessMsg: null,

    isSearchingClients: false,
    
    searchClientData: {},

    clientsPage: null,
}

export const mutations = {
    SET_LOADING(state, areClientsLoading) {
        state.areClientsLoading = areClientsLoading;
    },
    SET_CLIENTS_LIST(state, clientsList) {
        state.clientsList = clientsList
    },
    SET_CLIENTS_DATA(state, clientsData){
        state.clientsData = clientsData
    },
    SET_IS_SEARCHING(state, isSearchingClients) {
        state.isSearchingClients = isSearchingClients;
    },
    ADD_CLIENT(state, newClient){
        state.clientsList.push(newClient)
    },
    DELETE_CLIENT(state, clientId){
        state.clientsList = state.clientsList.filter(client => client.id !== clientId)
    },
    SET_ERROR_MSG(state, msg){
        state.clientErrorMsg = msg
    },
    SET_SUCCESS_MSG(state, msg){
        state.clientSuccessMsg = msg
    },
    UPDATE_CLIENT(state, updatedClient){
        state.clientsList = state.clientsList.map(
            client => 
            client.id === updatedClient.id ? updatedClient : client)
    },

    SET_SEARCH_DATA(state, searchClientData) {
        state.searchClientData = searchClientData;
    },

    SET_CLIENTS_PAGE(state, clientsPage){
        state.clientsPage = clientsPage
    }
}

export const getters = {
    getclientsList: state => state.clientsList,
    clientsData: state => state.clientsData,
    areClientsLoading: state => state.areClientsLoading,
    newClient: state => state.newClient,
    clientErrorMsg: state => state.clientErrorMsg,
    clientSuccessMsg: state => state.clientSuccessMsg,
    isSearchingClients: state => state.isSearchingClients,
    searchClientData: state => state.searchClientData,
    clientsPage: state => state.clientsPage
}

export const actions = {
    async fetchClientsList({commit}, page = 1){
        commit('SET_LOADING', true);

        await axios.get(`/api/v1/clients?page=${page}`)
        .then(response => {
            const { data } = response;
            commit('SET_CLIENTS_LIST', data)
            commit('SET_ERROR_MSG', null)
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_LOADING', false);
        });
    },
    

    async getClientsData({commit}, page = 1){
        commit('SET_LOADING', true);
         
        await axios.get(`/api/v1/clients?page=${page}`)
        .then(response => {
            const { data } = response;
            commit('SET_CLIENTS_DATA', data)
            commit('SET_ERROR_MSG', null)
            commit('SET_CLIENTS_PAGE', response.data.meta.current_page)
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_LOADING', false);
        });
    },

 
    async getSearchClientsData({ commit }, { page, searchDataObject }) {
        commit('SET_LOADING', true);
        commit('SET_IS_SEARCHING', true);

        commit('SET_SEARCH_DATA', searchDataObject);
      
        await axios.post(`api/v1/search-clients?page=${page}`, searchDataObject)
          .then((response) => {
            const { data } = response;
            commit('SET_CLIENTS_DATA', data);
            commit('SET_ERROR_MSG', null); 
            commit('SET_CLIENTS_PAGE', response.data.meta.current_page);
          })
          .catch((error) => {
            commit('SET_ERROR_MSG', error.response.data.message);
          })
          .finally(() => {
            commit('SET_LOADING', false);
          });
    },

    async addClient({ commit }, newClient) {
        try {
            const response = await axios.post(`/api/v1/clients`, newClient);
            const { data } = response;
    
            commit('ADD_CLIENT', data);
            commit('SET_ERROR_MSG', null);
            commit('SET_SUCCESS_MSG', 'Thank you for your submission');
    
            const theId = data.data.id;
            return theId;
        } catch (error) {
            commit('SET_ERROR_MSG', error.response.data.message);
            throw error;
        }
    },   
 
    async deleteClient({commit}, clientId){
        await axios.delete(`/api/v1/clients/${clientId}`)
        .then(() => {
            commit('DELETE_CLIENT', clientId)
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Client deleted successfully')
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
 

    async updateClient({commit}, updatedClient){
        await axios.patch(`/api/v1/clients/${updatedClient.id}`, updatedClient)
        .then(() =>{
            commit('UPDATE_CLIENT', updatedClient)
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Client updated successfully')
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    }
}