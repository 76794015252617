import axios from "axios";
export const state = {
    currentUser: JSON.parse(localStorage.getItem("user")) || null,
    authError: null,
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('user', newValue)
    },
    SET_AUTH_MSG(state, authError){
        state.authError = authError
    },
}

export const getters = {
    loggedIn(state) {
        return !!state.currentUser
    },
    getUserPermissions(state){
        if(!state.currentUser)
            return  null;
        const arr = state.currentUser.role.permissions
        return state.currentUser ? arr.map(element => element.name) : []   
    },
    getUserPermissionName(state){
        const arr = state.currentUser.role.name
        return arr   
    },
    authError: state => state.authError,
}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },
    async  getToken() {
        await axios.get("/sanctum/csrf-cookie");
    },

    async getUser({commit}) {
        const data = await axios.get("/api/v1/user");
        commit('SET_CURRENT_USER', data.data.data);
    },

    async logIn({commit, dispatch }, { email, password } = {}) {
        await dispatch("getToken");
      
        try {
          await axios.post("/login", {
            email,
            password,
          });
       
          commit("SET_AUTH_MSG", null);
      
          await dispatch("getUser");

        } catch (error) {
          if (error.response.status === 422) {
            if (error.response.data.errors.email) {
                commit("SET_AUTH_MSG", error.response.data.message); 
            } else if (error.response.data.errors.password) {
                commit("SET_AUTH_MSG", error.response.data.message);
            }
          } else {
            this.authError = "An error occurred during login. Please try again.";
          }
        }
      },


    async logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_CURRENT_USER', null)
        await axios.post("/logout");
        this.authUser = null;
    },
}

function saveState(key, state) {
    localStorage.setItem(key, JSON.stringify(state))
}