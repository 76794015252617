import axios from "axios";
import Swal from "sweetalert2";
import router from "./router";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://api.oneagency.ch";
//axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.headers =  {
    "Content-type": "application/json",
    "Accept": "application/json"
}
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const { status } = error.response;
        const { message } = error.response.data;
        if (status === 401) {
            localStorage.setItem("user", null);
            window.location.href = "/login";
            console.log(message);
            //return Promise.reject(error);
            //TODO : Implement tost
        } else if (status === 403) {   
            Swal.fire("You do not have permission!", error.response.data.message, "warning").then(()=>{
                router.push({
                    name: "Dashboard",
                });
            }) 
            //router.go(0);
            //TODO : Implement tost

        } else if (status === 500) {
            //TODO : Implement tost
        }
        return Promise.reject(error);
    }
);