import store from "@/state/store"; 
import checkRolePermission  from "@/helpers/checkRolePermission";
export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/Login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "/" });

        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/Register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "/" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: () => import("../views/account/ForgotPassword.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "/" });
        } else {
          next();
        }
      },
    },
  },

  {
    path: "/password-reset/:token",
    name: "resetPassword",
    component: () => import("../views/account/ResetPassword.vue"),
    meta: {
      title: "Reset Password",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "/" });
        } else {
          next();
        }
      },
    },
  },

  {
    path: "/job-management/:uuid",
    name: "jobManagement",
    component: () => import("../views/candidates/JobManagement.vue"),
    meta: {
      title: "Job Management",
 
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/account/LogOut.vue")
  },


  {
    path: "/",
    name: "Dashboard",
    meta: {
      title: "Dashboard",
      permission: "default",
    },
    component: () => import("../views/dashboard/Dashboard.vue"),
  },

  //candidate
  {
    path: "/candidates",
    name: "candidates",
    meta: {
      title: "Candidates",
      key: 'candidates'
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('candidate_access')) {
        next()
      } else {
        next({ name: '/' }) 
      }
    },
    component: () => import("../views/candidates/Candidates.vue"), 
  },
  {
    path: "/candidates/:id",
    name: "editCandidate",
    meta: {
      title: "Edit Candidate",
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('candidate_show')) {
        next()
      } else {
        next({ name: 'candidates' }) 
      }
    },
    component: () => import("../views/candidates/EditCandidate.vue"),
  },
  // companies
  {
    path: "/companies",
    name: "companies",
    meta: {
      title: "Companies",
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('company_access')) {
        next()
      } else {
        next({ name: '/' }) 
      }
    },
    component: () => import("../views/companies/Companies.vue"),
  },
  {
    path: "/companies/:id",
    name: "editCompany",
    meta: {
      title: "Edit Company",
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('company_edit')) {
        next()
      } else {
        next({ name: 'companies' }) 
      }
    },
    component: () => import("../views/companies/EditCompany.vue"),
  },

  // clients
  {
    path: "/clients",
    name: "clients",
    meta: {
      title: "Clients",
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('client_access')) {
        next()
      } else {
        next({ name: '/' }) 
      }
    },
    component: () => import("../views/clients/Clients.vue"),
  },

  {
    path: "/clients/:id",
    name: "editClient",
    meta: {
      title: "Edit Client",
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('client_edit')) {
        next()
      } else {
        next({ name: 'clients' }) 
      }
    },
    component: () => import("../views/clients/EditClient.vue"),
  },

  //vacancies
  {
    path: "/vacancies",
    name: "vacancies",
    meta: {
      title: "Vacancies",
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('vacancy_access')) {
        next()
      } else {
        next({ name: 'vacancies' }) 
      }
    },
    component: () => import("../views/vacancies/Vacancies.vue"),
  },
  {
    path: "/vacancies/:id",
    name: "editVacancies",
    meta: {
      title: "Edit Vacancies",
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('vacancy_show')) {
        next()
      } else {
        next({ name: 'vacancies' }) 
      }
    },
    component: () => import("../views/vacancies/EditVacancy.vue"),
  },

  // users
  {
    path: "/users",
    name: "users",
    meta: {
      title: "Users",
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('user_access')) {
        next()
      } else {
        next({ name: '/' }) 
      }
    },
    component: () => import("../views/users/Users.vue"),
  },
  {
    path: "/users/:id",
    name: "editUser",
    meta: {
      title: "Edit User",
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('user_edit')) {
        next()
      } else {
        next({ name: 'users' }) 
      }
    },
    component: () => import("../views/users/EditUser.vue"),
  },
  {
    path: "/userStats/:id",
    name: "userStats",
    meta: {
      title: "User Statics",
    },
    // beforeEnter: (to, from, next) => {
    //   if (checkRolePermission('user_edit')) {
    //     next()
    //   } else {
    //     next({ name: 'users' }) 
    //   }
    // },
    component: () => import("../views/users/UserStats.vue"),
  },

  // user logs
  {
    path: "/userLogs",
    name: "userLogs",
    meta: {
      title: "User Logs",
    },
    component: () => import("../views/users/UserLogs.vue"),
  },

  //skills
  {
    path: "/skills",
    name: "skills",
    meta: {
      title: "Skills",
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('skill_access')) {
        next()
      } else {
        next({ name: '/' }) 
      }
    },
    component: () => import("../views/skills/Skills.vue"),
  },

  //user roles
  {
    path: "/userRoles",
    name: "userRoles",
    meta: {
      title: "User Roles",
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('role_access')) {
        next()
      } else {
        next({ name: '/' }) 
      }
    },
    component: () => import("../views/users/UserRoles.vue"),
  },

  //permissions
  {
    path: "/userPermissions",
    name: "userPermissions",
    meta: {
      title: "UserPermissions",
    },
    beforeEnter: (to, from, next) => {
      if (checkRolePermission('permission_access')) {
        next()
      } else {
        next({ name: '/' }) 
      }
    },
    component: () => import("../views/users/UserPermissions.vue"),
  },

  //settings
  {
    path: "/settings",
    name: "settings",
    meta: {
      title: "Settings",
    },
    component: () => import("../views/settings/Settings.vue"),
  },

  //support
  {
    path: "/support",
    name: "support",
    meta: {
      title: "Support",
    },
    component: () => import("../views/support/Support.vue"),
  },


    //profile
    {
      path: "/profile/:id",
      name: "profile",
      meta: {
        title: "Profile",
      },
      component: () => import("../views/profile/Profile.vue"),
    }, 
];

